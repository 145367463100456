<template>
  <div class="fault-flow">
    <van-nav-bar
      left-arrow
      title="故障审批"
      @click-left="goBack()"
    ></van-nav-bar>
    <div class="main">
      <van-steps :active="-1" class="trouble-flow-steps" direction="vertical">
        <van-step v-for="(item, idx) in records" :key="idx">
          <h3>{{ item.statusName }}</h3>
          <p v-if="item.content">{{ item.content }}</p>
          <div class="intro">
            <span>
              <van-icon
                class-prefix="iconfont"
                class="intro-icon"
                color="#0079FF"
                name="icomember"
              />
              <b>{{ item.cuserName }}</b>
            </span>
            <span>
              <van-icon
                class-prefix="iconfont"
                class="intro-icon"
                color="#0079FF"
                name="icocalender"
              />
              <b>{{ item.ctime }}</b>
            </span>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { getApproveRecordsByPage } from "../../../api/psm/device";

import { Dialog } from "vant";
export default {
  name: "DeviceFaultFlow",
  data: () => ({
    // 隐患记录
    records: [],
    faultId: ""
  }),
  created() {
    if (!this.$route.query.faultId) {
      Dialog.confirm({
        title: "提示",
        message: "非正常进入页面",
        closeOnPopstate: true
      }).finally(() => this.$router.back());
    } else {
      this.faultId = this.$route.query.faultId;
      this.getRecords();
    }
  },
  methods: {
    async getRecords() {
      try {
        let params = {
          dataId: this.faultId,
          sort: "ctime_desc",
          page: 1,
          size: 100
        };
        let res = await getApproveRecordsByPage(params);
        this.records = res.list;
      } catch (e) {
        console.log("getRecords -> e", e);
      }
    },
    goBack() {
      this.$router.push({
        name: "DeviceFaultDetail",
        params: {
          id: this.faultId,
          equipmentId: this.$route.params.equipmentId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  height: calc(100vh - 58px);
  overflow: auto;
}
.trouble-flow-steps {
  padding-top: 2.4vh;
  margin: 0 10px;
  h3 {
    color: #3b4664;
    // font-weight: bold;
    margin-bottom: 1vh;
    font-size: 14px;
  }
  p {
    margin: 1vh 0;
    font-size: 14px;
    line-height: 24px;
    color: #aeb3c0;
  }
  .intro {
    display: flex;
    align-items: center;
    margin-top: 1vh;
    font-size: 13px;
    color: #aeb3c0;
    span {
      margin-right: 2vw;
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-right: 6vw;
      b {
        margin-left: 2vw;
      }
    }
  }
  .intro-icon {
    font-size: 12px;
  }
}

.fault-flow ::v-deep .van-step__circle {
  width: 6px;
  height: 6px;
  background-color: #0079ff;
  opacity: 0.4;
  transform: translateX(0.15vw);
}
.iconfont {
  opacity: 0.4;
}
</style>
