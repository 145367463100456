import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";
const equipment = apiPath.equipment;

/**
 * 设备故障类型
 * @param params
 * @returns {*}
 */
export const getDictDeviceFaultType = params => {
  return axios.get(`${equipment}/dict/device/fault/type`, { params });
};

/**
 * 设备故障状态
 * @param params
 * @returns {*}
 */
export const getDictDeviceFaultStatus = params => {
  return axios.get(`${equipment}/dict/select/check/status`, { params });
};

/**
 * 设备故障列表
 * @param {*} params
 * @returns
 */
export function getPageDeviceFaults(params) {
  return axios.get(`${equipment}/page/device/faults`, { params });
}

/**
 * 查询 设备故障详情
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getDeviceFaultInfo(id) {
  return axios.post(`${equipment}/info/device/faults/${id}`);
}

/**
 * 保存 设备故障详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export function saveDeviceFault(params) {
  return axios.post(`${equipment}/add/device/faults`, params);
}

/**
 * 更新设备故障详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export function updateSaveDeviceFault(params) {
  return axios.post(`${equipment}/update/device/faults/${params.id}`, params);
}

/**
 * 提交 设备故障详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export function submitDeviceFault(params) {
  return axios.post(`${equipment}/commit/device/faults`, params);
}

/**
 * 更新提交 设备故障详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export function updateSubmitDeviceFault(params) {
  return axios.post(`${equipment}/commit/device/faults/${params.id}`, params);
}

/**
 * 确认 设备故障详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export function confirmDeviceFault(params) {
  return axios.post(`${equipment}/confirm/device/faults/${params.id}`, params);
}

/**
 * 退回 设备故障详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export function rejectDeviceFault(params) {
  return axios.post(`${equipment}/reject/device/faults/${params.id}`, params);
}

/**
 * 查询对象的审批记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getApproveRecordsByPage(params) {
  return axios.get(`${equipment}/page/approve/records`, { params });
}
